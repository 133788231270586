import React, { useEffect, useState } from "react";
import { Card, Nav, Placeholder, Table } from "react-bootstrap";
import getWebsiteClicksByCluster from "../../../services/micro/get_website_clicks_by_cluster";

export function WebsitesTable({ inventoryId, campaignId, skin }) {
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        getWebsiteClicksByCluster(inventoryId, campaignId).then((data) => {
            setRowData(data.rows);
        })
    }, []);

    return (
        rowData.length ?
            <Card className="card-one">
                <Card.Header>
                    <Card.Title as="h6">Websites</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
                </Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th className="text-start">Website</th>
                                <th className="text-end">Website Traffic</th>
                                <th className="text-end">%</th>
                                <th className="text-end">Clicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-start">
                                        <img
                                            src={skin === "dark" ? item.dark_mode_logo : item.light_mode_logo}
                                            alt="logo"
                                            style={{ maxHeight: '20px', objectFit: 'contain' }}
                                        />
                                        <div><small className="text-secondary">{item.domain}</small></div>
                                    </td>
                                    <td className="text-end">{item.traffic}</td>
                                    <td className="text-end">{item.percentage}</td>
                                    <td className="text-end">{item.clicks}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div class="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
                        <i class="ri-information-line me-1"></i>
                        We routinely add new websites that adhere to our quality criteria.
                    </div>
                </Card.Body>
            </Card>
            :
            <React.Fragment>
                <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
                    <Placeholder style={{ height: 400, width: "100%" }} />
                </Placeholder>
            </React.Fragment>
    );
}