import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getWebsiteClicksByCluster(inventoryId, campaignId) {
    try {
        const currentPeriod = [
            new Date().setDate(new Date().getDate() - 30),
            new Date(),
        ];

        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/website/period/clicks-by-cluster', {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            campaign_id: Number(campaignId),
            inventory_id: Number(inventoryId)
        })
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
}

export default getWebsiteClicksByCluster;
