import React, { useEffect, useState } from "react";
import { Card, Col, Row, Placeholder } from "react-bootstrap";
import getTotalsByCluster from "../../../services/micro/get_totals_by_cluster";

const StatsCardList = ({ inventoryId, campaignId }) => {
    const [data, setData] = useState();

    useEffect(() => {
        getTotalsByCluster(inventoryId, campaignId).then(setData);
    }, [inventoryId, campaignId]);

    return (
        <Row className="g-3">
            {(data ? data : Array(4).fill({})).map((card, index) => (
                <Col md="6" key={index}>
                    {data ? (
                        <Card className="card-one">
                            <Card.Body>
                                <>
                                    <h2 className="card-value mb-1">{card.value}</h2>
                                    <Card.Title as="label" className="d-block mb-1 fw-medium text-dark">
                                        {card.label}
                                    </Card.Title>
                                    <small className="subtext">
                                        <span className={`d-inline-flex text-${card.status === "up" ? "success" : "danger"}`}>
                                            {card.change}{" "}
                                            <i className={`ri-arrow-${card.status === "up" ? "up" : "down"}-line`}></i>
                                        </span>{" "}
                                        than previous period
                                    </small>
                                </>
                            </Card.Body>
                        </Card>
                    ) : (
                        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
                            <Placeholder style={{ height: 112, width: "100%" }} />
                        </Placeholder>
                    )}
                </Col>
            ))}
        </Row>
    );
};

export default StatsCardList;
