import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getPeriodAdvertiserPerformance(currentPeriod, previousPeriod, status, search, page, inventoryId, sortingParams) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + `/api/analytics/advertiser/period/performance?status=${status}&page=${page}&search=${search}&column_name=${sortingParams?.columnName}&column_order=${sortingParams?.sortOrder}`, {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            previous_period: [new Date(previousPeriod[0]).getTime(), new Date(previousPeriod[1]).getTime()],
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodAdvertiserPerformance;
