import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    Card
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-charts-enterprise";
import { MissedClicksForecastCellTooltipRenderer, MissedClicksForecastRenderer } from "../MissedClicksForecastRenderer";
import { getServerSideDatasourceClusterPPC } from "../../../../helpers/get-serverside-datasource-cluster-ppc";
import { TopBidCellTooltipRenderer } from "../TopBidRenderer";
import { ClusterNameCellTooltipRenderer, ClusterNameRenderer } from "./ClusterNameRenderer";
import { ClusterActionCellTooltipRenderer, ClusterActionRenderer } from "./ClusterActionRenderer";
import { ClusterPositionCellTooltipRenderer, ClusterPositionRenderer } from "./ClusterPositionRenderer";
import { StatusCellTooltipRenderer, StatusRenderer } from "../StatusRenderer";
import { ClusterYourBidCellRenderer, ClusterYourBidCellTooltipRenderer } from "./ClusterYourBidRenderer";

const customColumns = [
    {
        field: "Topic",
        cellRenderer: ClusterNameRenderer,
        headerTooltip: "Inventory",
        tooltipValueGetter: ClusterNameCellTooltipRenderer
    },
    {
        field: "Position",
        cellRenderer: ClusterPositionRenderer,
        headerTooltip: "Your current position",
        tooltipValueGetter: ClusterPositionCellTooltipRenderer
    },
    {
        field: "Status",
        cellRenderer: StatusRenderer,
        headerTooltip: "Your current status",
        tooltipValueGetter: StatusCellTooltipRenderer
    },
    {
        field: "Your Bid",
        cellRenderer: ClusterYourBidCellRenderer,
        headerTooltip: "Your current bid",
        tooltipValueGetter: ClusterYourBidCellTooltipRenderer
    },
    {
        field: "Top Bid",
        headerTooltip: "Current top bid",
        tooltipValueGetter: TopBidCellTooltipRenderer
    },
    {
        field: "Action",
        cellRenderer: ClusterActionRenderer,
        headerTooltip: "Action",
        tooltipValueGetter: ClusterActionCellTooltipRenderer
    },
    {
        field: "Missed Clicks Forecast",
        cellRenderer: MissedClicksForecastRenderer,
        headerTooltip: "Missed clicks forecast for the next 30 days",
        tooltipValueGetter: MissedClicksForecastCellTooltipRenderer
    }
]

export const ClusterPPCAuctions = ({ getData, skin, onClick, campaignId, inventoryCampaign, updatePage }) => {
    const containerStyle = useMemo(() => ({ 
        width: "100%",
        height: "100%",
    }), []);
    const gridStyle = useMemo(() => ({
        minHeight: "200px",
        width: "100%",
    }), []);
    
    const [search, setSearch] = useState('');
    const [columnDefs, setColumnDefs] = useState([]);
    const [data, setData] = useState([]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const getDataPath = useCallback((data) => data.path, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Topic",
            field: "name",
            pinned: "left",
            tooltipValueGetter: ClusterNameCellTooltipRenderer,
            cellRendererSelector: (params) => { return {
                component: ClusterNameRenderer
            }},
            suppressHeaderFilterButton: true,
            sortable: false,
            menuTabs: [],
            suppressMenu: true,
            minWidth: 340,
            width: 340
        }
    });

    const onCellDoubleClicked = useCallback((params) => {
        if (params.colDef.showRowGroup) {
          params.node.setExpanded(!params.node.expanded);
        }
    }, []);
    
    const onCellKeyDown = useCallback((params) => {
        if (!("colDef" in params)) {
            return;
        }
        if (!(params.event instanceof KeyboardEvent)) {
            return;
        }
        if (params.event.code !== "Enter") {
            return;
        }
        if (params.colDef.showRowGroup) {
            params.node.setExpanded(!params.node.expanded);
        }
    }, []);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        getServerSideDatasourceClusterPPC(
            getData,
            setColumnDefs,
            search,
            customColumns,
            campaignId
        ).then((rows) => {
            setData(rows);
        }).catch((error) => {
            console.error(error);
        });
    }, [search, campaignId, updatePage]);

    if (data.length === 0) {
        return <></>;
    }

    return (
        <Card className="card-one">
            <Card.Header>
                <Card.Title as="h6">
                    Topic Cluster Auctions
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <div style={containerStyle}>
                    <div
                        style={gridStyle}
                        className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
                    >
                        <div style={{ marginBottom: "10px" }}>
                            <input
                                type="text"
                                className="input-search"
                                placeholder="Search..."
                                onInput={onFilterTextBoxChanged}
                            />
                        </div>
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            rowData={data}

                            rowHeight={65}
                            domLayout={"autoHeight"}
                            suppressContextMenu={true}

                            onCellClicked={onClick}

                            treeData={true}
                            groupDefaultExpanded={-1}
                            getDataPath={getDataPath}

                            onCellDoubleClicked={onCellDoubleClicked}
                            onCellKeyDown={onCellKeyDown}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};