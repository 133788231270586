import React, { useCallback, useEffect, useState } from "react";

export function ClusterNameRenderer({ node, value }) {
    const [expanded, setExpanded] = useState(node.expanded);

    useEffect(() => {
        const expandListener = (event) => setExpanded(event.node.expanded);

        node.addEventListener("expandedChanged", expandListener);

        return () => {
        node.removeEventListener("expandedChanged", expandListener);
        };
    }, []);

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

    // inventory.name + '@' + views + '@' + inventory.inventory_hirarchy_level + '@' + getPath(inventories, item.inventory_id).length + '@' + min_bid + '@' + status,
          
    const name = value.split("@")[0];
    const visits = value.split("@")[1];
    const inventory_hirarchy_level = value.split("@")[2];
    const pathLength = value.split("@")[3];
    const min_bid = value.split("@")[4];
    const status = value.split("@")[5];
    const allChildrenCount = node.allChildrenCount;
    const level = node.level + 1;


    return (
        <div 
            className="d-flex"
            style={{
                paddingLeft: `${node.level * 25}px`,
            }}
            onClick={onClick}
        >
            <div className="d-flex align-items-center">
                { node.group && (
                    <div
                        style={{
                            cursor: "pointer",
                            transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
                            display: "inline-block",
                        }}
                    >
                        <span style={{ fontSize: "20px" }}>
                            &rsaquo;
                        </span>
                    </div>
                )}
            </div>
            <div
                className='d-flex flex-column ms-3'
            >
                <div className="table-item-name">
                    {name} 
                    <div
                        className='badge bg-outline-secondary ms-2'
                        // title={`Current invetory recived ${visits} visits per last 30 days`}
                    >
                        {visits} Visits
                    </div>
                </div>
                <div 
                    className="align-items-center text-secondary"
                    style={{ fontSize: '12px', marginTop: '-15px' }}
                >
                    <span className='fw-bold'>Level {level} Topic {level === 1 ? 'Cluster' : 'Sub-Cluster'}</span>
                    { allChildrenCount > 0 && <span className=''>|</span> }
                    { allChildrenCount > 0 && <span className=''>{allChildrenCount} Topic Sub-Cluster{allChildrenCount > 1 ? 's' : ''}</span> }
                    <span className=''>|</span>
                    <span className=''>Min Bid <b>${min_bid}</b></span>
                    {/* <span style={{marginLeft: '10px'}} className="text-warning"> 
                        <span class="badge-dot me-2 bg-warning"></span>
                        {status.toLowerCase()}
                    </span> */}
                </div>
            </div>
        </div>
    );
}

export function ClusterNameCellTooltipRenderer({ value }) {
    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    console.log(name, visits);

    return `Current inventory recieved ${visits} visits per last 30 days`;
}
 