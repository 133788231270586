import React, { useEffect, useState } from "react";
import { Card, ListGroup, Nav, Placeholder } from "react-bootstrap";
import IconWithTooltip from "./IconWithTooltip";
import simpleTopList from "../../../assets/svg/simple_top_list.svg";
import topListOverview from "../../../assets/svg/top_list_overview.svg";
import simpleDynamicBanner from "../../../assets/svg/simple_dynamic_banner.svg";
import dynamic3PackBanner from "../../../assets/svg/dynamic_3pack_banner.svg";
import getPlacementClicksByCluster from "../../../services/micro/get_placement_clicks_by_cluster";

const imagesMap = {
    "Top List + Product Overview": topListOverview,
    "Simple Top List": simpleTopList,
    "Simple Dynamic Banner": simpleDynamicBanner,
    "Multi-Item Dynamic Banner": dynamic3PackBanner,
}

const BlockTypes = ({ inventoryId, campaignId, skin }) => {
    const logos = skin === "dark" ? 'darkLogos' : "whiteLogos"
    const [data, setData] = useState();

    useEffect(() => {
        getPlacementClicksByCluster(inventoryId, campaignId).then((response) => {
            if (response) {
                setData(response.data);
            }
        });
    }, [inventoryId]);

    if (data?.rows?.length === 0) {
        return (<></>)
    }

    if (data) {
        return (
            <Card className="card-one">
                <Card.Header>
                    <Card.Title as="h6">Block Types</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
                </Card.Header>
                <Card.Body>
                    <ListGroup as="ul" className="list-group-one">
                        {data.rows.map((item, index) => (
                            <ListGroup.Item
                                key={index}
                                as="li"
                                className="px-0 d-flex align-items-center gap-2 cursor-pointer subtext"
                            >
                                <div className="d-flex align-items-center">
                                    <Card.Img
                                        type="image/svg+xml"
                                        src={imagesMap[item.name]}
                                        variant="bottom"
                                        style={{ maxHeight: '50px' }}
                                    />
                                    <div style={{ minWidth: "350px" }}>
                                        <h6 className="mb-0 block-type-item-name">{item.name}</h6>
                                        <small className="me-1">
                                            {item.underText} featured on
                                            <span className="d-inline-flex align-items-center align-text-top">
                                                {item[logos].map((logo, index) => (
                                                    <img
                                                        key={`${item.name}-logo-${index}`}
                                                        src={logo}
                                                        alt="logo"
                                                        style={{ maxHeight: '15px', marginLeft: '5px' }}
                                                    />
                                                ))}
                                            </span>
                                        </small>
                                        <span style={{ fontSize: '16px' }}>
                                            <IconWithTooltip text="Here you can see publishers and their bids count" />
                                        </span>
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <h6 className="ff-numerals mb-0">{item.clicks} clicks</h6>
                                </div>

                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
        );
    } else {
        return (
            <React.Fragment>
              <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
                <Placeholder style={{ height: 240, width: "100%" }} />
              </Placeholder>
            </React.Fragment>
          );
    }
};

export default BlockTypes;