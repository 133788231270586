import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getTotalsByCluster(inventoryId, campaignId) {
    try {
        const currentPeriod = [
            new Date().setDate(new Date().getDate() - 30),
            new Date(),
        ];

        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/totals-cluster', {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            inventory_id: Number(inventoryId),
            campaign_id: Number(campaignId)
        })
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
}

export default getTotalsByCluster;
