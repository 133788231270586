import React, { useEffect, useState } from "react";

import { getInventoryCampaigns } from "../../services/micro";
import { switchSkin } from "../../helpers/switch-skin";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { AddItemButton } from "./components/AddItemButton";
import Footer from "../../layouts/Footer";
import { Nav } from "react-bootstrap";
import BasicTable from "../../components/BasicTable";
import { StatusRenderer } from "./components/StatusRenderer";

const colWidth = {
  campaign_id: 300,
  inventory_id: 300,
  cpc: 100,
  ppc_bid_active: 150,
  inventory_campaign_status: 100,
  approved_at: 200
};

const customColumns = [
  {
    field: "inventory_campaign_status",
    cellRenderer: StatusRenderer
  },
]

const hiddenColumns = ["primary_category_id", "primary_language_id"];
const rowFields = ["campaign_id", "inventory_id", "cpc", "ppc_bid_active", "inventory_campaign_status", "approved_at"]

export default function PublisherQueue() {
  const title = "Queue"
  const breadcrumb = "Inventory"
  const new_btn_title = "New Inventory Campaign"
  const new_btn_redirect = "/pub/invetory/campaign/new"
  const edit_redirect = "/pub/invetory/campaign/edit"
  const breadcrumb_redirect = "/pub/invetory/queue"
  const [status, setStatus] = useState(null);
  const [countByStatus, setCountByStatus] = useState({
    draftRecords: 0,
    reviewRecords: 0,
    liveRecords: 0,
    rejectedRecords: 0,
    total: 0
  });

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <Nav className="nav-line mb-4 ml-4">
          {<Nav.Link href="#draft" onClick={() => setStatus(4)}>Draft ({countByStatus.draftRecords})</Nav.Link>}
          {<Nav.Link href="#review" onClick={() => setStatus(3)}>Review ({countByStatus.reviewRecords})</Nav.Link>}
          {<Nav.Link href="#live" onClick={() => setStatus(1)}>Live ({countByStatus.liveRecords})</Nav.Link>}
          {<Nav.Link href="#rejected" onClick={() => setStatus(2)}>Rejected ({countByStatus.rejectedRecords})</Nav.Link>}
          {<Nav.Link href="#all" onClick={() => setStatus(null)} className={!status ? "active" : ''}>All ({countByStatus.total})</Nav.Link>}
        </Nav>

        <BasicTable getData={getInventoryCampaigns} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} status={status} setCountByStatus={setCountByStatus} customColumns={customColumns} skin={skin} />
        <Footer />
      </div>
    </React.Fragment>
  )
}