import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { login } from "../services/micro";
import { useDispatch } from 'react-redux';
import { clearUser, setUser } from '../features/user';
import { clearAdvertiser } from '../features/advertiser';
import { logosModule } from "../modules/logos";
import { LocalStorageKeyNames, useLocalStorageContext } from "../contexts/LocalStorageContext";
import { isIOSOrSafari } from "../helpers/is-ios-or-safari";

function getPublisherId() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
    return 34;
  }

  return urlParams.get('pub') || 1;
}

export const Signin = () => {
  const publisher_id = getPublisherId();
  const refresh = window.location.search.includes("refresh=true");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateLocalStorageProperty } = useLocalStorageContext();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    clearUser();
    clearAdvertiser();

    if (publisher_id) {
      localStorage.setItem("publisher_id", publisher_id);
    }

    if (localStorage.getItem("publisher_id")) {
      navigate("/pages/signin?pub=" + localStorage.getItem("publisher_id"));
      if (refresh) {
        navigate(0);
      }
    }
  }, [publisher_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter your email and password.");
      return;
    }

    login(email, password)
      .then((res) => {
        if (res.success) {
          toast.success("Login successful. Redirecting...");
          dispatch(setUser(res.data));

          if (res.data.default_theme === 'dark') {
            updateLocalStorageProperty(LocalStorageKeyNames.skinMode, 'dark');
          }
          updateLocalStorageProperty(LocalStorageKeyNames.token, res.data.token);
          updateLocalStorageProperty(LocalStorageKeyNames.publisherId, res.data.publisher_id);

          if (res.data.role !== "developer" && res.data.role !== "content_manager" && res.data.role !== "sales") {
            const navigateLink = isIOSOrSafari() ? "/pub/dashboard/revenue?refresh=true&token=" + res.data.token : "/pub/dashboard/revenue?refresh=true"
            navigate(navigateLink);
          } else {
            const navigateLink = isIOSOrSafari() ? "/pub/inventory/queue?refresh=true&token=" + res.data.token : "/pub/inventory/queue?refresh=true"
            navigate(navigateLink);
          }

        } else {
          toast.error("Login failed.");
        }
      })
      .catch((err) => {
        toast.error("Login failed.");
        console.log("Error", err);
      });
  };

  let siteLogo = logosModule(publisher_id);

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="pb-3">
          <Link to="/" className="header-logo">
            {siteLogo}
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body className="pb-3 pt-3">
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter your email address" />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Password <Link to="">Forgot password?</Link></Form.Label>
              <Form.Control onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="Enter your password" />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>
            <div className="divider"><span>Subject to Terms & Conditions</span></div>
          </Form>
        </Card.Body>
        {/* <Card.Footer className="pt-4">
          Don't have an account? <Link to="/pages/signup">Create One</Link>.
        </Card.Footer> */}
      </Card>
    </div>
  )
}