import {
    Card,
    Nav
} from "react-bootstrap";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { getServerSideDatasourcePPC } from "../../../helpers/get-serverside-datasource-ppc";
import "ag-grid-charts-enterprise";
import { AnalyticsButtonRenderer } from "./AnalyticsButtonRenderer";
import { PPCRenderer } from "./PPCRenderer";
import { logosMap } from "../../../modules/maps";
import { logosModule } from "../../../modules/logos";
import { MissedClicksForecastRenderer } from "./MissedClicksForecastRenderer";

const customColumns = [
    {
        field: "Missed Clicks Forecast",
        cellRenderer: MissedClicksForecastRenderer
    },
]

export const WebsiteSpecificPPCAuctions = ({ getData, skin, onClick, campaignId }) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);
    
    const [search, setSearch] = useState('');
    const [gridApi, setGridApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        const datasource = getServerSideDatasourcePPC(
            getData,
            setColumnDefs,
            search,
            customColumns,
            campaignId
        );

        params.api.setGridOption("serverSideDatasource", datasource);
    }, []);


    useEffect(() => {
        if (gridApi) {
            const datasource = getServerSideDatasourcePPC(
                getData,
                setColumnDefs,
                search,
                customColumns,
                campaignId
            );

            gridApi.setGridOption("serverSideDatasource", datasource);
        }
    }, [gridApi, search]);

    const currentSkin = localStorage.getItem("skin-mode");
    const logo = logosModule(67, currentSkin);

    return (
        <div>
            <div className="d-flex align-items-center mb-4">
                <div className="ht-50 bg-gray-800 border border-2 rounded-circle" style={{ width: 64, height: 64, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <i className="ri-check-double-line" style={{ fontSize: 36, color: "white" }}></i>
                </div>
                <div className="ms-3">
                    <h3 className="align-items-center d-flex text-uppercase">
                        <span className="text-bold">
                            Website Specific PPC Auctions
                        </span>
                        <span className="badge bg-outline-secondary text-secondary text-uppercase" style={{ marginLeft: 10 }}>
                            Live Bids
                        </span>
                    </h3>
                    <span className="text-muted">
                        Some websites have exclusive inventory. Manage specific inventory for each website here.
                    </span>
                </div>
            </div>
        
            <Card className="card-one">
                <Card.Body>
                    <Nav className="nav-line mb-4 ml-4 align-items-center">
                        {<Nav.Link href="#draft" onClick={() => {}}>All Websites</Nav.Link>}
                        {
                            <Nav.Link href="#draft" onClick={() => {}}>
                                {logo}
                            </Nav.Link>
                        }
                    </Nav>

                    <div style={containerStyle}>
                        <div
                            style={gridStyle}
                            className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
                        >
                            <div style={{ marginBottom: "10px" }}>
                                <input
                                    type="text"
                                    className="input-search"
                                    placeholder="Search..."
                                    onInput={onFilterTextBoxChanged}
                                />
                            </div>
                            <AgGridReact
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={false}
                                cacheBlockSize={10}
                                rowModelType={"serverSide"}
                                domLayout={"autoHeight"}
                                allowContextMenuWithControlKey={true}
                                onCellClicked={onClick}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};