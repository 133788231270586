import { abbreviationsMap } from "../../../modules/maps";

const HTR_PUBLISHER_ID = 67;
const STAGE_HTR_PUBLISHER_ID = 10;

function getPublisherId() {
    const searchParams = new URLSearchParams(window.location.search);
    const urlDomain = window.location.hostname;
    const pubParam = searchParams.get('pub');
    const publisherIdFromLocalStorage = localStorage.getItem("publisher_id");
    
    if (pubParam) {
      if (abbreviationsMap[pubParam]) {
        return abbreviationsMap[pubParam];
      } else {
        return pubParam;
      }
    }
  
    if (urlDomain === "ppc.hoteltechreport.com") {
      return 67;
    }
  
    if (urlDomain === "app.netnetix.com") {
      return 34;
    }
    
    if (publisherIdFromLocalStorage) {
      return Number(publisherIdFromLocalStorage);
    }
  
    return 1;
}

export function PositionRenderer({ value }) {
    if (!value) return null;

    const pubId = getPublisherId();

    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    if (campaign_active === "false") {
        return (
            <div class="d-flex align-items-center">
                {pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID ? "No bid" : "Not shown"}
            </div>
        );
    }

    if (currentPos > bids_amount || currentPos === 0) {
        if (pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID) {
            currentPos = "No bid";
        } else {
            currentPos = "Not shown";
        }
    }

    if (estimatedPos > bids_amount || estimatedPos === 0) {
        if (pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID) {
            estimatedPos = "No bid";
        } else {
            estimatedPos = "Not shown";
        }
    }

    if (currentPos !== "No bid" && currentPos !== "Not shown") {
        currentPos = "#" + currentPos;
    }

    if (estimatedPos !== "No bid" && estimatedPos !== "Not shown") {
        estimatedPos = "#" + estimatedPos;
    }

    if (estimatedPos === "No bid" || estimatedPos === "Not shown") {
        return (
            <div class="d-flex align-items-center">
                {pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID ? "No bid" : "Not shown"}
            </div>
        );
    }

    if (ppc_bid_active === "false" || campaign_active === "false" || ppc_paused === "true") {
        return (
            <div class="d-flex align-items-center">
                {pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID ? "No bid" : "Not shown"}
            </div>
        );
    }

    if (currentPos !== "No bid" && estimatedPos == currentPos && currentPos !== "Not shown") {
        return (
            <div class="d-flex align-items-center ml-1">
                <span>{estimatedPos}</span>
            </div>
        );
    }

    if (pubId === HTR_PUBLISHER_ID || pubId === STAGE_HTR_PUBLISHER_ID) {
        return (
            <div class="d-flex align-items-center">
                <span>{currentPos}</span>
            </div>
        );
    } else {
        return (
            <div class="d-flex align-items-center">
                <span>{currentPos}</span>
                <i class="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
                <span className="text-success">
                    <b>{estimatedPos}</b>
                </span>
            </div>
        );
    }
}

export function PositionCellTooltipRenderer({ value }) {
    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];

    if (bids_amount < currentPos) {
        return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks` // `You current position is not shown.`;
    }

    if (campaign_active === "false") {
        return `Your campaign is not active. That's why your position is not shown.`;
    }

    if (ppc_bid_active === "false") {
        return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks`;
    }

    if (ppc_paused === "true" && campaign_active === "true") {
        return `Your bidding is paused because you have reached your budget limit. That's why your position is not shown.`;
    }

    if (currentPos === 0) {
        return `You are not generating clicks in the auction currently. Make sure you have credits on your account as well as remaining budget for the month and have bidding enabled to become visible in the ad unit and start generating clicks` // `Your current position is not shown.`;
    }

    if (currentPos <= bids_amount && currentPos > 0) {
        if (currentPos === 1) {
            return `Your current position is #${currentPos}.`;
        } else {
            if (currentPos === estimatedPos) {
                return `Your current position is #${currentPos}.`;
            }

            return `Your current position is #${currentPos}. But you can get to position #${estimatedPos} if you increase your bid.`;
        }
    }
}