import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getPeriodCampaignInventoryStats(currentPeriod, previousPeriod, campaignId, current_token, view, page, search) {    
    try {
        const token = current_token || getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/inventory-stats', {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            previous_period: [new Date(previousPeriod[0]).getTime(), new Date(previousPeriod[1]).getTime()],
            campaign_id: campaignId,
            view: view
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodCampaignInventoryStats;
